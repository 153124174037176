import React from 'react';
import {
  PageLayout,
  PageLayoutBody,
  PageLayoutHeader,
  PageTitle,
} from '../components/page-layout';
import SEO from '../components/seo';

const Portfolio = () => (
  <PageLayout className="portfolio-background">
    <SEO title="Portfolio" />
    <PageLayoutHeader />
    <PageTitle>Portfolio</PageTitle>
    <PageLayoutBody className="portfolio-content">
      <iframe
        title="soundcloud-portfolio"
        width="100%"
        height="465"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/269890968&color=%2370d5dd&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true"
      />
    </PageLayoutBody>
  </PageLayout>
);

export default Portfolio;
